// extracted by mini-css-extract-plugin
export var content = "contact-module--content--1sfRW";
export var intro = "contact-module--intro--24TN4";
export var contact_wrapper = "contact-module--contact_wrapper--3Ppz8";
export var form_group = "contact-module--form_group--24pdv";
export var socialMediaPresence_desktop = "contact-module--socialMediaPresence_desktop--2vsYi";
export var socialMediaPresence_mobile = "contact-module--socialMediaPresence_mobile--3qu8r";
export var button = "contact-module--button--2NiwG";
export var errorText = "contact-module--errorText--NVWiZ";
export var model = "contact-module--model--25PqN";
export var fadeIn = "contact-module--fadeIn--2yuny";
export var modal_wrapper = "contact-module--modal_wrapper--OXKaM";
export var modal_header = "contact-module--modal_header--2-9GJ";
export var modal_content = "contact-module--modal_content--2UnmP";
export var modal_body = "contact-module--modal_body--1oBQ7";
export var modal_footer = "contact-module--modal_footer--2spVs";
export var show = "contact-module--show--ovFKX";